import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Tab Bar component is a graphical user interface element used for displaying multiple views or screens in a single window or page. It allows users to switch between different tabs, each representing a different view, with only one tab visible at a time.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tabbar/guideline-tabbar-anatomy.svg",
      "alt": "guideline tab bar"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Tab Bar container`}</strong>{`: The main container that holds all the tabs.`}</li>
      <li parentName="ol"><strong parentName="li">{`Tab item`}</strong>{`: A clickable item that represents a single tab.`}</li>
      <li parentName="ol"><strong parentName="li">{`Active indicator`}</strong>{`: A graphical element that indicates which tab is currently active.`}</li>
      <li parentName="ol"><strong parentName="li">{`Tab label`}</strong>{`: A label that describes the content of the tab.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Tab Bar component is used to Display multiple views or screens in a single window or page, Allow users to switch between different tabs easily and quickly, and can Provide a clear and organized navigation system for the user.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>When displaying multiple views or screens in a single window or page.</li>
      <li>When the content of each tab is distinct and can be clearly described by a label.</li>
      <li>When the number of tabs is limited to a reasonable amount.</li>
      <li>
        When you want to provide users with a visual representation of their current location within the application
      </li>
      <li>When you want to simplify navigation by reducing the number of buttons or links on the page</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>When the content of each tab is similar or related, making it difficult to distinguish between them.</li>
      <li>When the number of tabs is excessive, making it difficult to navigate and overwhelming for the user.</li>
      <li>When you want to provide users with a more detailed or complex navigation menu</li>
      <li>When screen real estate is limited and you need to prioritize other UI elements</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`When implementing the Tab Bar component, it’s important to ensure that it is accessible to all users. Here are some tips for making the Tab Bar component more accessible:`}</p>
    <ul>
      <li parentName="ul">{`Providing a clear and descriptive label for each tab.`}</li>
      <li parentName="ul">{`Test the component with assistive technologies to ensure that it is fully accessible`}</li>
      <li parentName="ul">{`Using semantic HTML markup for the component.`}</li>
      <li parentName="ul">{`Providing keyboard support for navigating between tabs.`}</li>
      <li parentName="ul">{`Ensuring that the active indicator is visible and clearly distinguishable from other elements.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Fit the right and proper amount of tabs in the Tab Bar. So users can utilize the function of Tab Bar component." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tabbar/guideline-tabbar-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Don't overload the Tab Bar with too many tabs or options. That could cause confusion for the users in using Tab Bar component." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tabbar/guideline-tabbar-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Tab Bar :`}</p>
    <ul>
      <li parentName="ul">{`Tab labels: Clear and descriptive labels that describe the content of each tab.`}</li>
      <li parentName="ul">{`Active indicator: A graphical element that indicates which tab is currently active. This can be a color change or an underline.`}</li>
      <li parentName="ul">{`Tab item: A clickable item that represents a single tab. The tab item can include an icon, label, or both.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use clear, concise labels or icons for each tab element. So it doesn’t causes some confusion and misunderstanding for the users in using Tab Bar component. " aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tabbar/guideline-tabbar-conten-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use unclear, vague labels or icons for each tab element. Because it could causes some confusion and misunderstanding for the users in using Tab Bar component. " aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tabbar/guideline-tabbar-conten-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      